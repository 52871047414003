@import 'Styles/Dansskolanentre/includes';

/**
*
* MenuPage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'MenuPage.Base.module.scss';

.MenuPage {
    $root: &;

    // top: 0;
    // left: 0;

    @include media(md) {
        font-size: 1.3rem;
    }

    &--Active {
        position: absolute;
        width: auto;
        left: -12px;
        right: -12px;
        height: 100vh;
        background: white;
        z-index: z(MenuPage);

        @include media(sm) {
            left: -20px;
            right: -20px;
        }

        @include media(md) {
            position: relative;
            left: auto;
            right: auto;
            height: auto;
            background: transparent;
        }
    }

    &__Content {
        #{$root}--Active & {
            background: white;
            box-shadow: 0 6px 7px 0 rgba(black, .2);
            position: relative;

            @include media(md) {
                background: transparent;
                box-shadow: none;

                &::after {
                    display: none;
                }
            }
        }
    }

    &__ContentInner {
        min-height: 100vh;
        padding-bottom: 8px;

        @include media(md) {
            min-height: 0;
            padding-bottom: 0;
        }
    }

    &__List {
        &--Children {
            #{$root}__List {
                &--Children {
                    display: none;
                    margin-left: 16px;
                }
            }

            #{$root}__Item--Open > & {
                display: block;
            }

            #{$root}__Item--Parent > & {
                position: relative;

                &::before {
                    content: '';
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 1.5px;
                    width: 1px;
                    height: 100%;
                    background: $grey-25;

                    @include media(md) {
                        display: block;
                    }
                }
            }
        }
    }

    &__Item {
        position: relative;
        transition: color .2s;

        &::after {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 3px;
            margin: auto 0;
            background-color: $primary-color;
            z-index: 1;
        }

        #{$root}__Item--Parent > #{$root}__List--Children > & {

            &--Open#{$root}__Item--HasChildren {

                @include media(md) {
                    padding-bottom: 10px;
                }
            }

            &--Current,
            &--CurrentAncestor {
                &::after {
                    display: block;
                }
            }
        }
    }

    &__Link {
        padding: 14px 52px 14px 24px;
        color: $text-color;
        font-size: 1.5rem;
        font-weight: $regular;
        line-height: 2.1rem;
        transition: background .2s;

        @include media(md) {
            padding: 12px 52px 13px 16px;
            line-height: 2.3rem;
            border: none;
        }

        &:hover {
            color: $link-color;
            text-decoration: underline;
        }

        #{$root}__List--Children & {
            text-transform: none;
        }

        #{$root}__Item--Parent > & {
            margin-bottom: 8px;
            font-weight: $bold;
            font-size: 1.6rem;

            @include media(md) {
                padding-left: 0;
            }
        }

        #{$root}__Item--Current > & {
            color: $primary-color;
            font-weight: $bold;
            background: transparent;
        }
    }

    &__Arrow {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: transparent;
        transition: background .2s;

        &:hover {
            background: $red-5;

            @media (hover: none) {
                background: transparent;
            }
        }

        &::before {
            right: 14px;
            width: 11px;
            height: 7px;
            background-image: url(#{$basepath}img/bg-icons/base/dropdown.svg);
            background-repeat: no-repeat;
        }

        @include media(md) {
            max-height: 41px;
        }

        #{$root}__List--Children #{$root}__Item--HasChildren > #{$root}__Link & {
            display: block;
        }

        #{$root}__List--Children #{$root}__Item--Open > #{$root}__Link & {
            &::before {
                transform: rotate(180deg);
            }
        }
    }
}

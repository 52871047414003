@import 'Styles/Base/includes';

/**
*
* ContactList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ContactList {
    $root: &;

    &__Container {
        padding: 30px 0;

        @include media(sm) {
            padding: 60px 0;
        }

        #{$root}:not(#{$root}--Full) & {
            padding: 16px 24px;

            @include media(sm) {
                padding: 32px 24px;
            }
        }
    }

    &__Title {
        margin-bottom: 10px;
        font-size: 2.4rem;
        font-weight: $bold;
        line-height: 1.4;

        #{$root}--Full & {
            @include media(sm) {
                font-size: 3.2rem;
            }
        }
    }

    &__Text {
        font-size: 1.6rem;
        line-height: 1.6;

        @include media(sm) {
            font-size: 1.8rem;
        }
    }

    &__SelectContainer {
        display: block;
        margin: 20px 0;

        @extend %Form__ReactSelect;

        #{$root}--Full & {
            margin-top: 30px;
        }
    }

    &__SelectLabel {
        margin-bottom: 10px;
        font-size: 1.8rem;
        font-weight: $bold;
    }
}

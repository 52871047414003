/*
    Two main buttons
    - Solid (default)
        - Regular and large, comes in different colors, and possibility for 
            shadow or icon
    - Rounded
        - Comes in black with border
    - Link
        - Button styled as a regular link
*/

@mixin u-button($list: (), $default: true) {
    @if $default {
        position: relative;
        display: inline-block;
        padding: 0;
        color: $link-color;
        font-family: $font-base;
        font-size: 1.6rem;
        font-weight: $medium;
        line-height: normal;
        text-decoration: none;
        border: none;
        border-radius: 2px;
        background: transparent;
        box-shadow: none;
        cursor: pointer;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        transition: color $transition, border-color $transition, background-color $transition;

        @include media(sm) {
            font-size: 1.8rem;
        }

        svg {
            margin: -5px 0 -5px 8px;
            height: 24px;
            width: 24px;
            transition: fill $transition, transform $transition;

            * {
                fill: $link-color;
            }
        }

        &:focus,
        &:focus-visible {
            outline-style: solid;
        }
    }

    @if contains($list, block) {
        display: block;
        width: 100%;
        max-width: none;
    }

    @if contains($list, link) {
        text-decoration: underline;

        &:hover {
            color: $link-hover-color;
            text-decoration: none;

            @media(hover: none) {
                color: $link-color;
                text-decoration: underline;
            }
        }
    } @else if contains($list, tertiary) {
        padding: 0 0 2px;
        border-bottom: 2px solid $link-color;

        &:hover {
            color: $link-hover-color;

            @media(hover: none) {
                color: $link-color;
            }
        }

        @if contains($list, disabled) {
            color: $grey-70;
            border-color: $grey-70;
            cursor: auto;

            svg * {
                fill: $grey-70;
            }

            &:hover {
                color: $grey-70;
                border-color: $grey-70;
            }
        }
    } @else if contains($list, primary) or contains($list, secondary) {
        padding: 12px 28px 13px;
        text-align: center;
        border: 2px solid $link-color;
        border-radius: 100px;
        background: white;

        @include media(sm) {
            padding: 15px 32px;
        }

        @if contains($list, primary) {
            color: white;
            background: $link-color;

            svg * {
                fill: white;
            }

            &:hover {
                border-color: $link-hover-color;
                background: $link-hover-color;

                @media(hover: none) {
                    border-color: $link-color;
                    background: $link-color;
                }

                svg {
                    transform: translateX(5px);

                    @media(hover: none) {
                        transform: translateX(0px);
                    }

                    * {
                        fill: white;
                    }
                }
            }

            @if contains($list, disabled) {
                color: $grey-70;
                border-color: $grey-20;
                background: $grey-20;
                cursor: auto;

                svg * {
                    fill: $grey-70;
                }

                &:hover {
                    color: $grey-70;
                    border-color: $grey-20;
                    background: $grey-20;
                }
            }
        } @else if contains($list, secondary) {
            color: $link-color;
            background: transparent;

            svg * {
                fill: $link-color;
            }

            &:hover {
                color: white;
                background-color: $link-color;

                &::after {
                    filter: brightness(0) invert(1);
                    transition: filter $transition;
                }

                svg {
                    transform: translateX(5px);

                    @media(hover: none) {
                        transform: translateX(0px);
                    }

                    * {
                        fill: white;
                    }
                }

                @media(hover: none) {
                    color: $link-color;
                    background-color: $link-color;

                    svg * {
                        fill: $link-color;
                    }
                }
            }

            @if contains($list, disabled) {
                color: $grey-70;
                border-color: $grey-70;
                cursor: auto;

                svg * {
                    fill: $grey-70;
                }

                &:hover {
                    color: $grey-70;
                    border-color: $grey-70;
                }
            }
        }
    }
}

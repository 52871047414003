@import 'Styles/Base/includes';

/**
*
* MenuPage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.MenuPage {
    $root: &;

    width: 100%;
    text-align: center;

    @include media(md) {
        position: relative !important;
    }

    &__Content {
        display: none;
        text-align: left;

        #{$root}--Active & {
            display: block;
        }

        @include media(md) {
            display: block;
        }
    }

    &__ContentInner {
        max-height: calc(100vh - 60px - 100px); // 60px for toggle, 100px for extra space at bottom in iOS
        overflow-y: scroll;

        @include media(md) {
            max-height: none;
            overflow-y: auto;
        }
    }

    &__Link {
        position: relative;
        display: block;
    }

    &__Arrow {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        width: 40px;
        height: 100%;
        cursor: pointer;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 25px;
            display: block;
            width: 14px;
            height: 10px;
            margin: auto;
            background-size: contain;
            transition: transform .2s;
        }
    }
}

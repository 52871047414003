$maxwidths: (
    site: 1160px,
    article: 1060px,
    title: 930px,
    content: 740px,
    cookie: 572px,
);

// Colors
$palettes: (
    black: (
        3: #F8F8F8,
        4: #F5F5F5,
        5: #F2F2F2,
        10: #E6E6E6,
        11: #e7e7e7,
        16: #D6D6D6,
        30: #E2E2E2,
        45: #727272,
        50: #818181,
        60: #6C6C6C,
        75: #525252,
        78: #444444,
        80: #333333,
        95: #272727,
        d: #000000,
    ),
    white: (
        d: #FFFFFF,
    ),
    yellow: (
        d: #EF9F0D,
        10: #FCECCF,
    ),
    purple: (
        d: #9B4392,
        hover: #8C3984,
        15: #F0E3EF,
    ),
    pink: (
        d: #DC0053,
        15: #fad9e5,
        hover: #CB0751,
    ),
    red: (
        d: #E3352C,
    ),
    green: (
        d: #07a069,
    ),
    medborgarskolan: (
        blue: #217AB7,
    ),
);

$text-color: palette(black);
$heading-color: palette(black);
$placeholder: palette(black, 33);
$link-color: palette(pink);
$link-hover-color: palette(pink, hover);
$error-color: palette(red);
$invalid-color: palette(red);
$valid-color: palette(green);
$focus-color: palette(purple);

$medborgarskolan-blue: palette(medborgarskolan, blue);

// Font weights
$normal: normal;
$medium: 500;
$bold: bold;
$black: 900;

$var-text-color: $text-color;
$var-heading-color: $heading-color;
$var-focus-color: $focus-color;
$var-body-bg: $body-bg;
$var-weight-heading: $weight-heading;

$font-size-h1-d: 3.2rem;
$font-size-h1-md: 7rem;
$font-size-h2-d: 2.4rem;
$font-size-h2-sm: 2.8rem;
$font-size-h3-d: 2rem;
$font-size-h3-sm: 2.4rem;

$z-index: (
    d: 10,
    behind: -1,
    CookiePopup: 700,
    MenuPage: 701, // Has to be more than cookie popup
    EventSearchInput__Select: 20, // Has to be more than EventItem
    EventSearchInput__Suggest: 22, // Has to be more than Select
    EventSearchInput__Search: 24, // Has to be more than Suggest
    EventItem: 10,
    CarNewsBackground: 1,
    MenuMobile__Content: 500,
    MenuMobile__Toggle: 520,
    Header__Container: 510,
    Modal__Backdrop: 600,
    Modal: 620,
    VideoContentModule__Content: 2,
    VideoPlayer__Video: 1,
);

@import 'Styles/Medborgarskolan/includes';

/**
*
* CardLink
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardLink.Base.module.scss';

.CardLink {
    $root: &;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 12px;
    margin: 35px 0;
    padding: 22px 32px;
    background: $purple-20;
    border-radius: 8px;

    @include media(sm) {
        padding: 32px 30px;
    }

    &--HasImage {
        padding: 0;

        @include media(sm) {
            min-height: 153px;
            padding: 32px 32px 32px (24px + 153px);
        }

        &::before {
            display: none;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 8px;
        border-radius: 8px 0 0 8px;
        background: $blue;
    }

    &__Image {
        position: relative;
        width: 100%;
        margin-bottom: 16px;

        @include media(sm) {
            position: absolute;
            top: 0;
            left: 0;
            width: 153px;
            height: 100%;
            margin-bottom: 0;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: #{percentage(176/312)};

            @include media(sm) {
                display: none;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 8px 8px 0 0;

            @include media(sm) {
                border-radius: 8px 0 0 8px;
            }
        }
    }

    &__Title {
        font-size: 2rem;
        color: $blue;

        @include media(sm) {
            font-size: 2.6rem;
        }

        &:hover {
            text-decoration: underline;
        }

        #{$root}--HasImage & {
            padding: 0 32px;

            @include media(sm) {
                padding: 0;
            }
        }
    }

    &__TitleText {
        padding-right: 30px;

        &::after {
            content: '';
            position: absolute;
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url('#{$basepath}img/bg-icons/medborgarskolan/new/arrow--blue.svg')
                no-repeat center center;
            background-size: contain;
            bottom: 0;
            right: 0;
            transition: transform $transition;

            @include media(sm) {
                bottom: 3px;
            }

            #{$root}__Link:hover & {
                transform: translateX(6px);
            }
        }
    }

    &__Content {
        #{$root}--HasImage & {
            padding: 0 32px 22px;

            @include media(sm) {
                padding: 0;
            }
        }
    }

    &__Link {
        @include href-external {
            #{$root}__TitleText {
                &::after {
                    background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/external--blue.svg');
                }
            }
        }
    }
}

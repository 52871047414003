%Form {
    $root: &;

    &__Field {
        position: relative;
        margin: 12px 0;

        &--CheckboxSelectMultiple,
        &--RadioSelect {
        }

        &--SelectMultiple {
            padding-top: 24px;
        }

        &--Consent {
            margin-top: 32px;

            @include media(sm) {
                margin-top: 40px;
            }
        }

        &--Title {
            margin: 32px 0 18px;

            &:first-child,
            input[type=hidden]:first-child + & {
                margin-top: 12px;
            }
        }
    }

    &__FieldTitle {
        font-size: 1.6rem;
    }

    &__Required {
        &::after {
            content: ' *';
        }
    }

    &__Label {
        position: relative;
        display: block;
        margin-bottom: 16px;
        font-size: 1.6rem;
        transition: all .3s ease-in-out;

        a {
            color: $link-color;

            &:hover {
                color: $link-hover-color;
                text-decoration: underline;

                @media(hover: none) {
                    color: $link-color;
                    text-decoration: none;
                }
            }
        }

        &--Inside {
            margin-bottom: 0;
            position: absolute;
            top: 18px;
            left: 20px;
            right: 20px;
            pointer-events: none;
        }

        &--InsideFocus {
            top: 12px !important;
            font-size: 1rem;
        }

        &--ForceAbove {
            margin-bottom: 0;
            position: absolute;
            top: -24px;
            left: 0;
        }

        // For checkbox and radio group labels
        &--Group {
            font-size: 1.6rem;
        }

        &--Select {
            padding: 10px;
            margin: -10px;
            background: white;
        }

        &--SelectFocus {
            padding: 0;
            margin: 0;
        }

        &--Checkbox {
            color: black;
            padding-left: 30px;
            cursor: pointer;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 2px;
                left: 0;
                display: block;
                border-radius: 2px;
                transition: all .3s ease-in-out;
            }

            &::before {
                width: 18px;
                height: 18px;
                border: 1px solid #eaeaea;
                background: white;
            }

            &::after {
                top: 6px;
                left: 4px;
                width: 12px;
                height: 11px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                opacity: 0;
            }
        }

        &--CheckboxChecked {
            &::after {
                opacity: 1;
            }
        }

        &--Radio {
            color: black;
            padding-left: 30px;
            cursor: pointer;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 2px;
                left: 0;
                display: block;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                transition: all .3s ease-in-out;
            }

            &::before {
                border: 1px solid #eaeaea;
                background: white;
            }

            &::after {
                top: 7px;
                left: 5px;
                width: 10px;
                height: 10px;
                opacity: 0;
            }
        }

        &--RadioChecked {
            &::after {
                opacity: 1;
            }
        }

        &--RadioLarge {
            padding: 16px 16px 16px 44px;
            margin-bottom: 0;

            &::before {
                top: 18px;
                left: 16px;
            }

            &::after {
                top: 23px;
                left: 21px;
            }

            @include media(xs) {
                padding: 20px 24px 20px 50px;

                &::before {
                    top: 22px;
                    left: 20px;
                }

                &::after {
                    top: 27px;
                    left: 25px;
                }
            }
        }

        &--Disabled {
            color: #ccc;
            cursor: auto !important;

            &::after {
                display: none !important;
            }
        }
    }

    &__Input,
    &__Select {
        padding: 25px 20px 11px 20px;
        width: 100%;
        color: black;
        font-size: 1.6rem;
        font-family: $font-base;
        font-weight: $medium;
        border: 1px solid #eaeaea;
        background: white;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        outline: none;
        transition: all .3s ease-in-out;

        &--Checkbox,
        &--Radio {
            padding: 0 !important;
            margin: 0;
            border: none;
            position: absolute;
            opacity: 0;
        }

        &--LabelAbove {
            padding-top: 19px !important;
            padding-bottom: 17px !important;
        }

        &[disabled],
        &--Disabled {
            color: #ccc;
        }
    }

    &__Select {
        text-overflow: '';
        text-indent: 0.01px;
        -webkit-appearance: none;
        -moz-appearance: none;

        &::-ms-expand {
            display: none;
        }

        option {
            color: black;
            font-size: 1.6rem;
            font-family: $font-base;
            font-weight: $medium;
        }

        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 black;
        }

        &--Multiple {
            margin-top: 5px;
            padding: 10px 20px;
        }
    }

    &__ReactSelect {
        :global {
            .Select {
                width: 100%;
                color: black;
                font-size: 1.6rem;
                font-family: $font-base;
                font-weight: $medium;

                &--LabelInside {
                    .Select__value-container {
                        top: 3px;
                        line-height: 48px;
                    }
                }

                .Select__control {
                    position: relative;
                    border: 1px solid #eaeaea;
                    border-radius: 0;
                    background: white;
                    transition: all .3s ease-in-out;

                    &:hover {
                        box-shadow: none;
                    }

                    &--is-focused {
                        &:not(.Select__control--menu-is-open) {
                            box-shadow: none;
                        }
                    }
                }

                .Select__multi-value-wrapper {
                    display: block;
                    position: relative;
                }

                .Select__placeholder {
                    color: #aaa;
                }

                .Select__value-container {
                    padding-left: 20px !important;
                    font-size: 1.6rem !important;
                    font-weight: $medium !important;

                    @include media(md) {
                        font-size: 1.6rem !important;
                    }
                }

                .Select__input {
                    padding: 0;
                    font-weight: $medium !important;
                }

                .Select__menu {
                    border: 1px solid #eaeaea;
                    border-radius: 0;
                    box-shadow: none;
                    z-index: 2;
                    transition: all .3s ease-in-out;
                }
            }
        }
    }

    &__FieldWrapper {
        position: relative;

        label {
            @extend %Form__Label;
        }

        &--Select {
            &::before {
                content: '';
                width: 60px;
                display: block;
                position: absolute;
                top: 3px;
                bottom: 3px;
                right: 3px;
                background: white;
                pointer-events: none;
            }

            &::after {
                content: '';
                height: 9px;
                width: 16px;
                margin: auto;
                position: absolute;
                top: 0px;
                bottom: 0px;
                right: 25px;
                background: url(../#{$basepath}img/bg-icons/base/dropdown.svg) no-repeat center center;
                pointer-events: none;
            }

            > select + label {
                @extend %Form__Label--Select;
            }
        }

        input[type="checkbox"],
        input[type="radio"] {
            @extend %Form__Input--Checkbox;
        }

        > input:not([type="checkbox"]):not([type="radio"]),
        > textarea {
            @extend %Form__Input;
        }

        > select {
            @extend %Form__Select;
        }

        > input:not([type="checkbox"]):not([type="radio"]),
        > textarea {
            + label {
                @extend %Form__Label--Inside;
            }

            &:focus + label,
            &.js-state-has-value + label {
                @extend %Form__Label--InsideFocus;
            }
        }
    }

    &__Error {
        margin-top: 5px;
        color: $error-color;
        font-size: 1.4rem;

        &--Large {
            position: relative;
            margin-top: 30px;
            padding: 14px 20px;
            text-align: center;
            border: 1px solid $error-color;
            background: white;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                background: rgba($error-color, .04);
                border-radius: 0;
            }
        }

        ul {
            margin: -10px 0;
            text-align: left;
            clear: both;

            li {
                margin-top: 10px;
                margin-bottom: 10px;
                padding-left: 22px;
                position: relative;

                &::before {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    width: 5px;
                    height: 5px;
                    border-radius: 20px;
                    background: $error-color;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                }
            }
        }
    }

    &__Helptext {
        margin-top: 5px;
        color: #696969;
        font-size: 1.4rem;

        a {
            color: $link-color;

            &:hover {
                color: $link-hover-color;
                text-decoration: underline;

                @media(hover: none) {
                    color: $link-color;
                    text-decoration: none;
                }
            }
        }
    }

    &__Submit {
        margin-top: 12px;

        &:is(input) {
            box-shadow: -4px 4px 8px rgba(black, .2);
        }

        #{$root}--Module &,
        &--Module {
            box-shadow: none !important;

            &::before {
                display: none;
            }
        }
    }
}

@import 'Styles/Kulturama/includes';

/**
*
* CardLink
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardLink.Base.module.scss';

.CardLink {
    margin: 35px 0;
    padding: 30px 20px 33px;
    border: 1px solid palette(black, 10);

    @include media(sm) {
        padding: 35px 45px 40px;
    }

    &--HasImage {
        padding: 16px 16px 24px;

        @include media(sm) {
            min-height: 153px;
            padding: 32px 32px 32px (24px + 153px);
        }

        &::before {
            display: none;
        }
    }

    &__Image {
        position: relative;
        width: 100%;
        margin-bottom: 16px;

        @include media(sm) {
            position: absolute;
            top: 0;
            left: 0;
            width: 153px;
            height: 100%;
            margin-bottom: 0;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: #{percentage(176/312)};

            @include media(sm) {
                display: none;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__Title {
        color: palette(pink);
        font-size: 1.8rem;
        font-weight: $bold;

        @include media(sm) {
            font-size: 2rem;
        }

        &:hover {
            text-decoration: underline;
        }

    }

    &__TitleText {
        display: inline-block;
        padding-left: 23px;
        font-weight: $medium;
        font-size: 1.8rem;

        @include media(md) {
            font-size: 2rem;
        }

        &:before {
            content: '';
            width: 15px;
            height: 12px;
            position: absolute;
            top: 7px;
            left: 0;
            background: url(#{$basepath}img/bg-icons/kulturama/arrow--pink.svg) no-repeat center center;
            background-size: contain;
        }
    }

    &__Text {
        line-height: 1.44;
    }
}

@import 'Styles/Kulturama/includes';

/**
*
* CardContact
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardContact.Base.module.scss';

.CardContact {
    $root: &;

    &__Container {
        align-items: flex-start;
        padding: 0;
        border: none;

        #{$root}--Sidebar & {
            flex-direction: column;
            min-width: 252px;
        }
    }

    &__ImageContainer {
        flex: 1 0 auto;
        width: 80px;
        height: auto !important;
        margin-right: 16px;

        @include media(sm) {
            width: 160px;
            margin-right: 24px;
        }

        #{$root}--ListLarge & {
            @include media(sm) {
                width: 248px;
            }
        }

        #{$root}--Sidebar & {
            width: 100%;
            margin-right: 0;
            height: 142px !important;
        }
    }

    &__Image {
        position: relative !important;
        height: auto !important;
        object-fit: contain !important;

        #{$root}--Sidebar & {
            height: 100% !important;
            object-fit: cover !important;
        }
    }

    &__Content {
        display: flex;
        flex-direction: column;
        padding: 0;

        #{$root}--Sidebar & {
            width: 100%;
            background-color: palette(black, 3);
            padding: 16px;
            word-break: break-word;
        }
    }

    &__Label {
        margin-top: 6px;
        margin-bottom: 0;
        font-size: 1.2rem;
        font-weight: $normal;
        letter-spacing: 0.32px;
        text-transform: uppercase;
        line-height: 1.16;

        @include media(sm) {
            font-size: 1.6rem;

            #{$root}--Sidebar & {
                font-size: 1.2rem;
            }
        }

        #{$root}--ListCompact & {
            margin-top: 0;
            letter-spacing: 0;
            text-transform: none;
        }
        #{$root}--ListAccordion & {
            margin-top: 0;
            letter-spacing: 0;
            text-transform: none;
        }
    }

    &__Title {
        order: -1;
        margin-bottom: 0;
        font-size: 2rem;
        font-weight: $medium;

        @include media(sm) {
            font-size: 2.4rem;

            #{$root}--Sidebar & {
                font-size: 2rem;
            }
        }

        #{$root}--Small & {
            line-height: 28px;
        }

        // Add comma between label and title if both exist
        #{$root}--ListCompact #{$root}__Label + & {
            &::after {
                content: ',';
                margin-right: 5px;
            }
        }

        #{$root}--ListAccordion #{$root}__Label + & {
            &::after {
                content: ',';
                margin-right: 5px;
            }
        }
    }

    &__Text {
        margin-top: 20px;
        margin-bottom: 0;
        font-size: 1.4rem;
        line-height: 1.47;

        @include media(sm) {
            font-size: 1.8rem;
            line-height: 1.56;

            #{$root}--Sidebar & {
                font-size: 1.4rem;
                line-height: 1.47;
            }
        }
    }

    &__PageLink {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0;
        margin-top: 10px;
        color: $link-color;
        font-weight: $medium;

        &::after {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-left: 10px;
            background-image: url('#{$basepath}img/bg-icons/kulturama/arrow-small--pink.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }

        #{$root}--Small & {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border: none;
            background: palette(black, 3);

            @include href-external() {
                &::after {
                    background-image: url('#{$basepath}img/bg-icons/kulturama/external--pink.svg');
                }
            }
        }
    }

    &__Address {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;

        &::before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 10px;
            background-image: url('#{$basepath}img/bg-icons/kulturama/pin--pink.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            #{$root}--Sidebar & {
                width: 20px;
                height: 20px;
            }
        }

        #{$root}--Sidebar & {
            font-size: 1.4rem;
        }
    }

    &__Links {
        margin-top: 20px;
        gap: 4px;

        #{$root}--ListCompact & {
            margin-top: 4px;
        }

        #{$root}--ListAccordion & {
            margin-top: 4px;
        }
    }

    &__Link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 28px;
        color: palette(black, 80);
        text-decoration: underline;

        #{$root}--Sidebar & {
            font-size: 1.4rem;
        }

        &:hover {
            text-decoration: none;
        }

        &::before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 10px;
            background-image: url('#{$basepath}img/bg-icons/kulturama/arrow-small--pink.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            #{$root}--Sidebar & {
                width: 20px;
                height: 20px;
            }
        }

        @include href-external() {
            &::before {
                background-image: url('#{$basepath}img/bg-icons/kulturama/external--pink.svg');
            }
        }

        &--Email {
            &::before {
                background-image: url('#{$basepath}img/bg-icons/kulturama/mail--pink.svg') !important;
            }
        }

        &--Phone {
            &::before {
                background-image: url('#{$basepath}img/bg-icons/kulturama/phone--pink.svg') !important;
            }
        }

        &--SocialMedia {
            justify-content: center;
            width: 32px;
            height: 32px;
            background: palette(pink, 15);
            transition: background-color .3s ease-in-out;

            &:hover {
                background: white;
            }

            &::before {
                margin: 0 !important;
            }
        }

        &--Facebook {
            &::before {
                width: 20px;
                height: 20px;
                background-image: url('#{$basepath}img/bg-icons/kulturama/facebook--pink.svg') !important;
            }
        }

        &--Instagram {
            &::before {
                background-image: url('#{$basepath}img/bg-icons/kulturama/instagram--pink.svg') !important;
            }
        }

        &--Linkedin {
            &::before {
                width: 20px;
                height: 20px;
                background-image: url('#{$basepath}img/bg-icons/kulturama/linkedin--pink.svg') !important;
            }
        }

        &--Tiktok {
            &::before {
                background-image: url('#{$basepath}img/bg-icons/kulturama/tiktok--pink.svg') !important;
            }
        }

        &--Twitter {
            &::before {
                width: 20px;
                height: 20px;
                background-image: url('#{$basepath}img/bg-icons/kulturama/twitter-x--pink.svg') !important;
            }
        }

        &--Youtube {
            &::before {
                background-image: url('#{$basepath}img/bg-icons/kulturama/youtube--pink.svg') !important;
            }
        }
    }
}

@mixin media($key) {
    @if $key == 'd' {
        @content;
    } @elseif map-has-key($breakpoints, $key) {
        @media (min-width: map-get($breakpoints, $key)) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin media-max($key) {
    @if $key == 'd' {
        @content;
    } @elseif map-has-key($breakpoints, $key) {
        @media (max-width: #{map-get($breakpoints, $key) - 1}) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin clearfix() {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin focus() {
    outline: 1px dotted;
    outline: -webkit-focus-ring-color auto 5px;
}

@mixin gradient-horizontal-three-colors($start-color: #555, $mid-color: #333, $color-stop: 50%, $end-color: #555) {
  background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 gets no color-stop at all for proper fallback
}

@mixin href-download() {
    &[href] {
        &[href$='.pdf'],
        &[href$='.doc'],
        &[href$='.docx'],
        &[href$='.ppt'],
        &[href$='.pptx'],
        &[href$='.xls'],
        &[href$='.xlsx'],
        &[href$='.odt'],
        &[href$='.eps'],
        &[href$='.zip'] {
            @content;
        }
    }
}

@mixin href-mailto() {
    &[href^='mailto:'] {
        @content;
    }
}

@mixin href-tel() {
    &[href^='tel:'] {
        @content;
    }
}

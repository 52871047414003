@import 'Styles/Pahlmans/includes';

/**
*
* CardLink
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardLink.Base.module.scss';

.CardLink {
    margin: 35px 0;
    padding: 35px 50px 35px 50px;
    background: white;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 4px;
        background: $primary-color;
    }

    &--HasImage {
        padding: 16px 16px 24px;

        @include media(sm) {
            min-height: 153px;
            padding: 32px 32px 32px (24px + 153px);
        }

        &::before {
            display: none;
        }
    }

    &__Image {
        position: relative;
        width: 100%;
        margin-bottom: 16px;

        @include media(sm) {
            position: absolute;
            top: 0;
            left: 0;
            width: 153px;
            height: 100%;
            margin-bottom: 0;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: #{percentage(176/312)};

            @include media(sm) {
                display: none;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }


    &__Title {
        font-size: 2.2rem;

        &:hover {
            color: $primary-color;
        }
    }

    &__TitleText {
        padding-right: 23px;
        font-size: 2.4rem;
        font-weight: $medium;
        max-width: 600px;

        &:after {
            content:' ';
            display: inline-block;
            margin-left: 15px;
            width: 23px;
            height: 15px;
            background-repeat: no-repeat;
            background-image: $bgArrow;
        }
    }

    &__Text {
        font-weight: $normal;
        max-width: 600px;
    }
}

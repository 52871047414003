@import 'Styles/Kulturama/includes';

/**
*
* ContactList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import './ContactList.Base.module';

.ContactList {
    $root: &;

    margin: 32px 0;

    @include media(sm) {
        margin: 40px 0;
    }

    &--Accordion {
        background-color: palette(black, 3);
    }

    &__Container {
        padding: 0 !important;
    }

    &__Toggle {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 19px 20px;
        text-align: left;
        border: none;
        border-radius: 0;
        background: transparent;
        box-shadow: none;
        cursor: pointer;
        -webkit-appearance: none;

        @include media(sm) {
            padding: 19px 24px;
        }

        &::after,
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 20px;
            width: 20px;
            height: 2px;
            margin: auto;
            background-color: palette(pink);

            @include media(sm) {
                right: 24px;
            }
        }

        &::before {
            transform: rotate(90deg);
            transition: all .2s ease-in-out;
        }

        #{$root}--isOpen & {
            &::before {
                transform: rotate(180deg);
            }
        }
    }

    &__Title {

        @include media(sm) {
            font-size: 2.8rem;
        }

        #{$root}--Accordion & {
            margin: 0;
            font-size: 2rem;
            font-weight: 700;
            color: palette(black, 100);

            @include media(sm) {
                font-size: 2.4rem;
            }
        }
    }

    &__Accordion {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows .2s ease-in-out;

        #{$root}--isOpen & {
            grid-template-rows: 1fr;
        }
    }

    &__AccordionContent {
        margin-top: -20px;
        overflow: hidden;

        @include media(sm) {
            margin-top: -24px;
        }
    }

    &__List {
        margin-top: 20px;

        @include media(sm) {
            margin-top: 24px;
        }
    }

    &__Item {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid palette(black, 30);

        @include media(sm) {
            margin-bottom: 24px;
            padding-bottom: 24px;
        }

        #{$root}--Compact & {
            padding-bottom: 0;
            border: none;
        }

        #{$root}--Accordion & {
            margin-bottom: 0;
            padding: 0 20px 20px;
            border: none;

            @include media(sm) {
                padding: 0 24px 24px;
            }
        }
    }
}

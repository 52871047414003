%Form {
    // Add to body to override Base/extends/form.scss
    body &__Field {
        position: relative;
        margin: 12px 0;

        &--CheckboxSelectMultiple,
        &--RadioSelect {
        }

        &--SelectMultiple {
            padding-top: 24px;
        }

        &--Consent {
            margin-top: 32px;

            @include media(sm) {
                margin-top: 40px;
            }
        }

        &--Title {
            margin: 32px 0 18px;

            &:first-child,
            input[type=hidden]:first-child + & {
                margin-top: 12px;
            }
        }

        &--CheckboxInput .Form__FieldWrapper,
        &--Consent .Form__FieldWrapper,
        &--CheckboxSelectMultiple .Form__FieldWrapper ul li {
            > label {
                color: black;
                padding-left: 30px;
                cursor: pointer;

                &::before,
                &::after {
                    top: 4px;
                }

                &::before {
                    border: 1px solid #d0d0d0;
                }

                &::after {
                    top: 9px;
                    background-image: url(../#{$basepath}img/bg-icons/kulturama/check--white.svg);
                }
            }

            > input:checked + label {
                &::before {
                    border-color: palette(pink);
                    background: palette(pink);
                }
            }
        }

        &--RadioSelect .Form__FieldWrapper ul li {
            > label {
                &:hover::before {
                    border-color: palette(pink);
                }

                &::before {
                    border: 1px solid #D0D0D0;
                }

                &::after {
                    background: palette(pink);
                }
            }

            > input:checked + label {
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    body &__FieldTitle {
        font-weight: $bold;
    }

    body &__Label {
        color: #696969;
        font-weight: $normal;
        line-height: 1.6;

        &--Checkbox {
            color: black;
            padding-left: 30px;
            cursor: pointer;

            &::before,
            &::after {
                top: 4px;
            }

            &::before {
                border: 1px solid #d0d0d0;
            }

            &::after {
                top: 9px;
                background-image: url(../#{$basepath}img/bg-icons/kulturama/check--white.svg);
            }
        }

        &--CheckboxChecked {
            &::before {
                border-color: palette(pink);
                background: palette(pink);
            }
        }

        &--Radio {
            &:hover::before {
                border-color: palette(pink);
            }

            &::before {
                border: 1px solid #D0D0D0;
            }

            &::after {
                background: palette(pink);
            }
        }

        &--RadioChecked {
            &::after {
                opacity: 1;
            }
        }
    }

    body &__Input,
    body &__Select {
        border: 1px solid #e9e9e9;

        &:focus {
            border-color: palette(pink);
        }
    }

    body &__ReactSelect {
        :global {
            .Select {
                .Select__control {
                    border: 1px solid #e9e9e9;

                    &--is-focused {
                        &,
                        + .Select__menu {
                            border-color: palette(pink);
                        }
                    }
                }

                .Select__menu {
                    border: 1px solid #e9e9e9;
                }
            }
        }
    }

    body &__Field {
        &--CheckboxSelectMultiple .Form__FieldWrapper ul li,
        &--RadioSelect .Form__FieldWrapper ul li {
            padding-top: 0;
        }
    }

    body &__Submit {
        @include u-button(solid pink);
    }
}

$maxwidths: (
    site: 1160px,
    article: 1060px,
    title: 930px,
    content: 680px,
    cookie: 572px,
);

// Colors
$palettes: (
    black: (
        2: #FBF9F8, // Site background
        3: #F8F4F2,
        4: #F6F2F0,
        5: #F2EFED,
        10: #EDEDED,
        15: #E7E7E7,
        20: #d9d9d9,
        46: #8A8989,
        60: #6A6A6A,
        65: #525252,
        72: #474747, // Body text
        78: #444444,
        84: #232323,
        86: #161F24,
    ),
    red: (
        d: #820032, // PHI secondary
        system: #db0027,
    ),
    purple: (
        d: #7A3476, // PGY secondary
    ),
    blue: (
        5: #F2F9FB,
        15: #e6f0f3,
        34: #8C9DA9,
        47: #7D93A1,
        50: #2C6D8A,
        80: #2E3C44,
        d: #006584, // PHI Primary
    ),
    green: (
        76: #F2FBF5,
        56: #86A497, // PGY Billboard
        44: #758F84,
        60: #09663E, // PGY Footer
        d: #033D24, // PGY Primary
        system: #09663E,
    ),
    medborgarskolan: (
        blue: #217AB7,
    ),
);

// Font weights
$normal: normal;
$medium: 500;
$semibold: 600;
$bold: bold;
$black: 900;

$weight-heading: $medium;

$font-size-h1-d: 2.8rem;
$font-size-h1-md: 3.2rem;
$font-size-h2-d: 2.4rem;
$font-size-h2-sm: 2.8rem;
$font-size-h3-d: 2rem;
$font-size-h3-sm: 2.6rem;

$z-index: (
    d: 10,
    behind: -1,
    CookiePopup: 700,
    MenuPage: 701, // Has to be more than cookie popup
    EventItem: 10,
    EventSearchInput__Select: 11, // Has to be more than EventItem
    EventSearchInput__Suggest: 12, // Has to be more than Select
    EventSearchInput__Search: 13, // Has to be more than Suggest
    SearchItem: 10,
    MenuMobile__Content: 500,
    MenuMobile__Toggle: 520,
    Header__Container: 110, // Has to be more that header backdrop
    Header__Backdrop: 100,
    Header__Content: 520,
    Back__Btn: 530,
    Modal__Backdrop: 600,
    Modal: 620,
    VideoContentModule__Content: 2,
    VideoPlayer__Video: 1,
);

$var-text-color: palette(black, 72);
$var-heading-color: black;
$var-focus-color: palette(blue);
$var-body-bg: palette(black, 2);
$var-weight-heading: $weight-heading;

$medborgarskolan-blue: palette(medborgarskolan, blue);

// Theme css variables, PHI and gymnasium have different values for these variables
$body-bg: var(--body-bg);
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$focus-color: $secondary-color;
$text-color: var(--text-color);
$heading-color: var(--heading-color);
$billboard-color: var(--billboard-color);
$billboard-button-hover-color: var(--billboard-button-hover-color);
$footer-color: var(--footer-color);
$tag-color: var(--tag-color);
$menu-hover: var(--menu-hover);
$menu-active: var(--menu-active);
$menu-icon-hover: var(--menu-icon-hover);
$pagination-hover-color: var(--pagination-hover-color);
$calendar-hover-color: var(--calendar-hover-color);
$cookie-accordion: var(--cookie-accordion);
$search-item-event-color: var(--search-item-event-color);
$link-color: var(--link-color);
$link-hover-color: var(--link-hover-color);
$error-color: var(--error-color);
$invalid-color: var(--invalid-color);
$valid-color: var(--valid-color);
$button-ghost-border-before-color: var(--button-ghost-border-before-color);
$button-ghost-border-after-color: var(--button-ghost-border-after-color);
$bgImage: var(--bg-image);
$bgArrow: var(--bg-arrow);
$dropdown: var(--dropdown);
$download: var(--download);
$bgCheck: var(--bg-check);
$bgAlert: var(--bg-alert);
$cookie: var(--cookie);

@import 'Styles/Medborgarskolan/includes';

/**
*
* CardContact
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardContact.Base.module.scss';

.CardContact {
    $root: &;

    margin: 20px 0;

    @include media(sm) {
        margin: 24px 0;

        #{$root}--Sidebar & {
            margin: 0;
        }
    }

    &__Container {
        position: relative;
        padding: 24px 16px;
        border-color: $blue-20;
        border-radius: 8px;
        background: $blue-20;

        @include media(sm) {
            padding: 32px 24px;
        }

        #{$root}--SmallImage & {
            flex-direction: row;
        }

        #{$root}--LargeImage & {
            flex-direction: column;
        }

        #{$root}--Sidebar & {
            padding: 16px;
        }
    }

    &__ImageContainer {
        display: block;
        min-width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        #{$root}--SmallImage & {
            position: absolute;
            top: 24px;
            left: 16px;
            min-width: 0;
            max-width: 64px;
            width: 100%;
            height: 64px;
            margin-right: 16px;
            border-radius: 100%;

            @include media(sm) {
                top: 32px;
                left: 24px;
                max-width: 96px;
                height: 96px;
            }
        }

        #{$root}--LargeImage & {
            width: 100%;
            margin: 20px 0 24px;
            height: 216px;
            border-radius: 0;
        }
        
        #{$root}--Sidebar & {
            top: 16px;
            left: 16px;
            max-width: 88px;
            width: 100%;
            height: 88px;
            border-radius: 8px;

            @include media(sm) {
                max-width: 96px;
                height: 96px;
            }
        }
    }

    &__Content {
        padding: 0;
        width: 100%;
    }

    &__Header {
        #{$root}--Sidebar & {
            flex-direction: column-reverse;
            padding-top: 100px;

            @include media(sm) {
                padding-top: 115px;
            }

        }

        #{$root}--NoImage & {
            padding-top: 0;
        }
    }

    &__Label {
        margin-bottom: 8px;
        font-size: 1.2rem;
        font-weight: $bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $blue;

        #{$root}--SmallImage & {
            margin-top: 10px;
            padding-left: 80px;

            @include media(sm) {
                padding-left: 120px;
            }
        }
        
        #{$root}--Sidebar & {
            font-size: 1.4rem;
            font-weight: $normal;
            text-transform: capitalize;
            padding-left: 0;
            margin-top: 0;

            @include media(sm) {
                font-size: 1.6rem;
            }
        }
    }

    &__Title {
        font-size: 3rem;
        font-weight: $bold;
        color: $blue;

        @include media(sm) {
            font-size: 3.8rem;
        }

        #{$root}:not(#{$root}--LargeImage) & {
            padding-bottom: 24px;
            margin-bottom: 20px;
            border-bottom: 1px solid $blue-30;

            @include media(sm) {
                margin-bottom: 24px;
            }
        }

        #{$root}--SmallImage & {
            padding-left: 80px;
            min-height: (64px + 24px);

            @include media(sm) {
                padding-left: 120px;
                min-height: (96px + 24px);
            }
        }

        #{$root}--SmallImage #{$root}__Label + & {
            min-height: (64px + 24px - 26px);

            @include media(sm) {
                min-height: (96px + 24px - 36px);
            }
        }

        #{$root}--Sidebar:not(#{$root}--LargeImage) & {
            margin-bottom: 0;
            padding-bottom: 0;
            padding-left: 0;
            min-height: 0;
            font-size: 2.4rem;
            border-bottom: none;

            @include media(sm) {
                font-size: 2.6rem;
            }
        }
    }

    &__Meta {
        display: block;
    }

    &__Address,
    &__Link,
    &__Text {
        font-size: 1.6rem;

        #{$root}--Sidebar & {
            font-size: 1.4rem;

            @include media(sm) {
                font-size: 1.6rem;
            }
        }
    }

    &__Address {
        position: relative;
        padding-left: 32px;

        &::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 3px;
            left: 0;
            background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/location--blue.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &__Link {
        margin-top: 12px;
        line-height: 1.25;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }

        &--Phone,
        &--Email {
            &::before {
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                margin: -4px 12px -4px 0;
                background: no-repeat center center;
                background-size: contain;
            }
        }

        &--Phone {
            &::before {
                background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/phone--blue.svg');
            }
        }

        &--Email {
            &::before {
                background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/mail--blue.svg');
            }
        }
    }

    &__PageLink {
        @include u-button(tertiary);
        color: $blue;

        &:after {
            background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/arrow--blue.svg');
            top: 5px;
            width: 24px;
            height: 24px;
        }

        #{$root}--Small & {
            border-color: $blue-20;
            border-radius: 8px;
            background: $blue-20;
        }
    }

    &__Socials {
        display: none;
    }
}

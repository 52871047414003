@import 'Styles/Dansskolanentre/includes';

/**
*
* CardLink
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardLink.Base.module.scss';

.CardLink {
    $root: &;

    margin: 30px 0;
    padding: 20px;
    color: white;
    border-radius: 8px;
    background-image: $gradient-color;
    overflow: hidden;

    @include media(sm) {
        margin: 40px 0;
        padding: 32px;
        border-radius: 12px;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $primary-color;
        opacity: 0;
        transition: opacity .2s;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    &--HasImage {
        padding: 16px 16px 24px;

        @include media(sm) {
            min-height: 153px;
            padding: 32px 32px 32px (24px + 196px);
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $primary-color;
            opacity: 0;
            transition: opacity .2s;
        }
    
        &:hover {
            &::before {
                opacity: 1;
            }
        }
    }

    &__Image {
        position: relative;
        width: 100%;
        margin-bottom: 16px;

        @include media(sm) {
            position: absolute;
            top: 0;
            left: 0;
            width: 198px;
            height: 100%;
            margin-bottom: 0;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: #{percentage(176/312)};

            @include media(sm) {
                display: none;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__Title {
        display: block;
        max-width: 515px;

        #{$root}__Link & {
            @extend %sr-only;
        }
    }

    &__Link {
        position: absolute;
        top:0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        z-index: z();
    }

    &__Content {
        position: relative;
        padding-right: 52px;

        @include media(sm) {
            padding-right: 60px;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 44px;
            height: 44px;
            margin: auto;
            border-radius: 50%;
            background: rgba(white, .2) url('#{$basepath}img/bg-icons/dansskolanentre/arrow--white.svg') no-repeat center center;
            background-size: 24px 24px;

            @include media(sm) {
                right: 8px;
            }
        }
    }

    &__TitleText {
        display: inline-block;
        font-size: 2.2rem;
        font-weight: $bold;

        @include media(sm) {
            font-size: 2.6rem;
        }
    }

    &__Text {
        max-width: 515px;
        margin-top: 12px;
        line-height: 1.52;
        font-weight: $light;

        &:empty {
            display: none;
        }
    }
}

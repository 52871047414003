@import 'Styles/Kulturama/includes';

/**
*
* MenuPage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'MenuPage.Base.module.scss';

.MenuPage {
    $root: &;

    // top: 0;
    // left: 0;

    @include media(md) {
        font-size: 1.8rem;
    }

    &--Active {
        position: absolute;
        width: auto;
        left: -12px;
        right: -12px;
        height: 100vh;
        background: rgba(black, .4);
        z-index: z(MenuPage);

        @include media(sm) {
            left: -20px;
            right: -20px;
        }

        @include media(md) {
            position: relative;
            left: auto;
            right: auto;
            height: auto;
            background: transparent;
        }
    }

    &__Content {

        #{$root}--Active & {
            background: palette(white);
            box-shadow: 0 6px 7px 0 rgba(black, .2);
            position: relative;

            /*&::after {
                content: '';
                position: absolute;
                bottom: 0px;
                left: 0;
                right: 0;
                height: 20px;
                pointer-events: none;
                background: linear-gradient(to bottom, rgba(palette(white), 0), rgba(palette(white),1) 30%);
            }*/

            @include media(md) {
                background: transparent;
                box-shadow: none;

                &::after {
                    display: none;
                }
            }
        }
    }

    &__List {
        &--Children {
            #{$root}__List {
                &--Children {
                    display: none;
                }
            }

            #{$root}__Item--Open > & {
                display: block;
            }
        }
    }

    &__Item {
        position: relative;
        transition: background .2s;

        &::after {
            content: '';
            display: none;
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0;
            width: 4px;
            margin: auto 0;
            background-color: palette(pink);
            z-index: 1;
        }

        #{$root}__List--Children & {
            &:hover {
                background: palette(black, 3);
            }

            &--HasChildren {
                &#{$root}__Item--Current,
                &#{$root}__Item--CurrentAncestor {
                    background: palette(black, 3);
                }
            }
        }

        #{$root}__Item--Parent > #{$root}__List--Children > & {

            &--Open#{$root}__Item--HasChildren {
                
                @include media(md) {
                    padding-bottom: 10px;
                }
            }

            &--Current,
            &--CurrentAncestor {
                &::after {
                    @include media(md) {
                        display: block;
                    }
                }
            }
        }
    }

    &__Link {
        padding: 14px 50px 14px 52px;
        color: palette(black, 65);
        font-size: 1.5rem;
        font-weight: $medium;
        line-height: 2.1rem;
        border-bottom: 1px solid palette(black, 10);
        transition: background .2s;

        @include media(sm) {
            padding-right: 60px;
        }

        @include media(md) {
            padding-left: 20px;
            padding-top: 9px;
            padding-bottom: 9px;
            font-size: 1.8rem;
            line-height: 2.3rem;
            text-transform: uppercase;
            border: none;
        }

        &:hover {
            text-decoration: none;
            background: palette(black, 3);
        }

        #{$root}__List--Children & {
            text-transform: none;
        }

        #{$root}__Item--Current > & {
            color: palette(pink);
            background: transparent;

            @include media(md) {
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 3px;
                    bottom: 3px;
                    left: 0;
                    width: 4px;
                    background-color: palette(pink);
                }
            }
        }

        #{$root}__List--Children #{$root}__List--Children & {
            padding-left: 62px;
            font-weight: $normal;

            &:hover {
                background: palette(black, 4);
            }

            @include media(sm) {
                padding-left: 70px;
            }

            @include media(md) {
                padding-left: 30px;
            }
        }

        #{$root}__List--Children #{$root}__List--Children #{$root}__List--Children & {
            padding-left: 72px;

            @include media(sm) {
                padding-left: 80px;
            }

            @include media(md) {
                padding-left: 40px;
            }
        }

        #{$root}__List--Children #{$root}__List--Children #{$root}__List--Children #{$root}__List--Children & {
            padding-left: 82px;

            @include media(sm) {
                padding-left: 90px;
            }

            @include media(md) {
                padding-left: 50px;
            }
        }

        #{$root}__List--Children #{$root}__List--Children #{$root}__List--Children #{$root}__List--Children #{$root}__List--Children & {
            padding-left: 92px;

            @include media(sm) {
                padding-left: 100px;
            }

            @include media(md) {
                padding-left: 60px;
            }
        }
    }

    &__Arrow {
        max-height: 50px;

        &::before {
            background: url(#{$basepath}img/bg-icons/base/dropdown.svg) no-repeat center;
        }

        @include media(md) {
            max-height: 41px;
        }

        #{$root}__List--Children #{$root}__Item--HasChildren > #{$root}__Link & {
            display: block;
        }

        #{$root}__List--Children #{$root}__Item--Open > #{$root}__Link & {
            &::before {
                transform: rotate(180deg);
            }
        }
    }
}

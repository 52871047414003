$maxwidths: (
    site: 1260px,
    article: 1046px,
    title: 830px,
    content: 618px,
    cookie: 572px,
);


// Medborgarskolan v2 colors
$grey: #9E9E9E;
$grey-5: #FAFAFA;
$grey-10: #F5F5F5;
$grey-20: #EEEEEE;
$grey-30: #E0E0E0;
$grey-40: #BDBDBD;
$grey-60: #757575;
$grey-70: #616161;
$grey-80: #424242;
$grey-90: #262626;

$blue: #211B83;
$blue-10: #F5FAFB;
$blue-20: #DCECEF;
$blue-30: #BED5DA;
$blue-90: #17135C;
$blue-100: #0A0828;

$coral: #EF7765;  // Korallröd klar
$coral-80: #D85F4E;  // Korallröd dov
$coral-20: #FFD3CF;  // Korallröd pastell

$yellow: #D5A249;  // Gulockra klar
$yellow-80: #976F2B;  // Gulockra dov
$yellow-20: #FFEDCF;  // Gulockra pastell

$purple: #C985C3;  // Bärskär klar
$purple-80: #A758A5;  // Bärskär dov
$purple-70: #AE5EAC;  // Focus state
$purple-20: #EAD5EA;  // Bärskär pastell
$purple-10: #F5EAF5;

$green: #50A597;  // Mysgrön klar
$green-80: #345E58;  // Mysgrön dov

$system-red: #CC4B35;
$system-green: #327566;

$text-color: $grey-90;
$heading-color: $grey-90;
$placeholder: $grey-80;
$link-color: $blue;
$link-hover-color: $blue-90;
$error-color: $system-red;
$invalid-color: $system-red;
$valid-color: $system-green;
$focus-color: $purple-70;

// Font weights, only add the ones used by the fonts
$normal: normal;
$medium: 500;
$bold: bold;

$var-text-color: $text-color;
$var-heading-color: $heading-color;
$var-focus-color: $focus-color;
$var-body-bg: $body-bg;
$var-weight-heading: $weight-heading;

$font-size-h1-d: 4.4rem;
$font-size-h1-md: 6.4rem;
$font-size-h2-d: 3.4rem;
$font-size-h2-sm: 4.8rem;
$font-size-h3-d: 3rem;
$font-size-h3-sm: 3.8rem;

$transition: .3s ease-in-out;

$z-index: (
    d: 10,
    behind: -1,
    CookiePopup: 1200,
    Header__Container: 110, // Has to be more that header backdrop
    Header__Backdrop: 100,
    Header__Content: 520,
    EventItem: 10,
    EventSearchInput__Select: 11, // Has to be more than EventItem
    EventSearchInput__Suggest: 12, // Has to be more than Select
    EventSearchInput__Search: 13, // Has to be more than Suggest
    SearchItem: 10,
    MenuMobile__Content: 500,
    MenuMobile__Toggle: 510,
    Modal__Backdrop: 1100,
    Modal: 1120,
    VideoContentModule__Content: 2,
    VideoPlayer__Video: 1,
);

@import 'Styles/Tillskararakademin/includes';

/**
*
* CardLink
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardLink.Base.module.scss';

.CardLink {
    margin: 30px 0;
    padding: 20px;
    border-left: 3px solid palette(green);

    @include media(sm) {
        margin: 40px 0;
    }

    &--HasImage {
        padding: 16px 16px 24px;

        @include media(sm) {
            min-height: 153px;
            padding: 32px 32px 32px (24px + 153px);
        }

        &::before {
            display: none;
        }
    }

    &__Image {
        position: relative;
        width: 100%;
        margin-bottom: 16px;

        @include media(sm) {
            position: absolute;
            top: 0;
            left: 0;
            width: 153px;
            height: 100%;
            margin-bottom: 0;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: #{percentage(176/312)};

            @include media(sm) {
                display: none;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }


    &__Title {
        font-size: 1.8rem;
        font-weight: $bold;

        @include media(sm) {
            font-size: 2rem;
        }
    }

    &__Link {
        &:hover h2 span {
            text-decoration: underline;
        }
    }

    &__TitleText {
        font-weight: $bold;
        font-size: 2.6rem;

        &:after {
            content: '';
            position: absolute;
            display: inline-block;
            width: 17px;
            height: 15px;
            margin-left: 10px;
            background: url('#{$basepath}img/bg-icons/tillskararakademin/arrow.svg') no-repeat;
            background-size: contain;
            bottom: 7px;
        }
    }

    &__Text {
        line-height: 1.6;
        display: flex;
        flex-flow: column;
        color: palette(black, 22);
    }
}

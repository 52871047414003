@import 'Styles/Pahlmans/includes';

/**
*
* CardContact
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardContact.Base.module.scss';

.CardContact {
    $root: &;

    &__Container {
        flex-direction: column !important;
        padding: 12px;
        border-color: palette(black, 10);
        border-radius: 4px;
        background: white;
    }

    &__ImageContainer {
        display: none;

        @include media(sm) {
            display: block;
        }
    }

    &__Content {
        display: flex;
        flex-direction: column;
        padding: 16px 8px 12px;
    }

    &__Label {
        margin-bottom: 16px;
        font-weight: $medium;
    }

    &__Title {
        order: -1;
        margin-bottom: 8px;
        font-weight: $medium;
    }

    &__Address {
        margin-bottom: 8px;
    }

    &__PageLink {
        font-weight: $medium;
        text-decoration: underline;

        &:after {
            background-image: url('#{$basepath}img/bg-icons/pahlmans/arrow--blue.svg');
        }

        #{$root}--Small & {
            border-color: palette(black, 10);
            background: white;
        }
    }

    &__Link {
        position: relative;
        text-decoration: underline;
        word-break: break-word;

        &:hover {
            text-decoration: none;
        }

        &:not(:last-child) {
            margin-bottom: 12px;
        }

        &--Phone,
        &--Email {
            padding-left: 32px;

            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 1px;
                left: 0;
                width: 24px;
                height: 24px;
                margin-bottom: -7px;
                background: no-repeat center center;
                background-size: contain;
            }
        }

        &--Phone {
            &::before {
                background-image: url('#{$basepath}img/bg-icons/pahlmans/phone--blue.svg');
            }
        }

        &--Email {
            &::before {
                background-image: url('#{$basepath}img/bg-icons/pahlmans/mail--blue.svg');
            }
        }
    }

    &__Socials {
        display: none;
    }
}

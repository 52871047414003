@import 'Styles/Base/includes';

/**
*
* CardContact
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardContact {
    $root: &;

    &__Container {
        display: flex;
        padding: 10px;
        border: 1px solid;

        @include media(sm) {
            padding: 15px 14px;
        }
    }

    &__ImageContainer {
        position: relative;
        min-width: 114px;
        overflow: hidden;

        #{$root}--SmallImage & {
            height: 136px;
        }

        #{$root}--LargeImage & {
            margin: 20px 0 24px;
            height: 216px;
            border-radius: 4px;
        }

        #{$root}--ListCompact & {
            display: none;
        }

        #{$root}--ListAccordion & {
            display: none;
        }
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__Content {
        padding: 10px 10px;
        font-size: 1.4rem;
        font-weight: $normal;

        @include media(sm) {
            padding: 15px 20px;
            font-size: 1.6rem;
        }
    }

    &__Header {
        display: flex;
        flex-direction: column;

        #{$root}--ListCompact & {
            flex-direction: row;
        }

        #{$root}--ListAccordion & {
            flex-direction: row;
        }
    }

    &__Label {
        margin-bottom: 10px;
        font-size: 1.6rem;
        font-weight: $bold;

        #{$root}--ListCompact & {
            font-size: 1.6rem;
            line-height: 1.33;

            @include media(sm) {
                font-size: 1.8rem;
            }
        }
        
        #{$root}--ListAccordion & {
            font-size: 1.6rem;
            line-height: 1.33;

            @include media(sm) {
                font-size: 1.8rem;
            }
        }
    }

    &__Title {
        margin-bottom: 5px;
        font-weight: $bold;
        line-height: 1.4;
        font-size: 1.8rem;

        @include media(sm) {
            font-size: 2rem;
        }

        #{$root}--Small & {
            display: inline-block;
            margin: 0;
            font-size: 1.6rem;
        }

        #{$root}--ListCompact & {
            font-size: 1.6rem;
            line-height: 1.33;

            @include media(sm) {
                font-size: 1.8rem;
            }
        }

        #{$root}--ListAccordion & {
            font-size: 1.6rem;
            line-height: 1.33;

            @include media(sm) {
                font-size: 1.8rem;
            }
        }
    }

    &__Text {
        margin: 5px 0 10px;
        white-space: pre-line;

        #{$root}--ListCompact & {
            display: none;
        }

        #{$root}--ListAccordion & {
            display: none;
        }
    }

    &__PageLink {
        position: relative;
        display: inline-block;
        margin-bottom: 24px;
        font-weight: $bold;
        font-size: 1.6rem;

        #{$root}--ListCompact & {
            display: none;
        }

        #{$root}--ListAccordion & {
            display: none;
        }

        &:after {
            content: '';
            position: relative;
            top: 1px;
            display: inline-block;
            width: 15px;
            height: 12px;
            margin-left: 8px;
            background: no-repeat center center;
            background-size: contain;
            transition: transform .2s ease-in-out;
        }

        &:hover::after {
            transform: translateX(5px);

            @media(hover: none) {
                transform: translateX(0px);
            }
        }

        #{$root}--Small & {
            display: block;
            margin: 0;
            padding: 17px 20px;
            border: 1px solid;

            @include media(sm) {
                padding: 20px 34px;
            }
        }
    }

    &__Address {
        white-space: pre-line;

        #{$root}--ListCompact & {
            display: none;
        }

        #{$root}--ListAccordion & {
            display: none;
        }
    }

    &__Links {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        #{$root}--ListCompact & {
            flex-direction: row;
            gap: 8px 16px;
        }

        #{$root}--ListAccordion & {
            flex-direction: row;
            gap: 8px 16px;
        }
    }

    &__Link {
        display: block;
        color: $link-color;

        &:hover {
            text-decoration: underline;

            @media(hover: none) {
                text-decoration: none;
            }
        }
    }

    &__Socials {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 20px;

        #{$root}--ListCompact & {
            display: none;
        }

        #{$root}--ListAccordion & {
            display: none;
        }
    }
}

@import 'Styles/Medborgarskolan/includes';

/**
*
* MenuPage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'MenuPage.Base.module.scss';

.MenuPage {
    $root: &;

    position: absolute;
    top: 0;
    left: 0;
    background-color: $blue-10;
    border-radius: 8px;

    &--Active {
        z-index: 1;
    }

    &__Content {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__ContentInner {
        max-height: none;
    }

    &__List {
        &--Children {
            #{$root}__List {
                &--Children {
                    display: none;
                    padding-left: 14px;
                    padding-bottom: 4px;

                    @include media(md) {
                        padding-left: 18px;
                        padding-bottom: 0px;
                    }
                }
            }

            #{$root}__Item--Open > & {
                display: block;
            }
        }
    }

    &__Item {
        position: relative;
        transition: background 0.2s;

        &::before {
            content: '';
            display: none;
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0;
            width: 4px;
            margin: auto 0;
            background-color: $blue;
            z-index: 1;
        }

        #{$root}__List--Children & {
            &--HasChildren {
                &#{$root}__Item--Current,
                &#{$root}__Item--CurrentAncestor {
                    background: $blue-10;
                }
            }
        }

        #{$root}__Item--Parent > #{$root}__List--Children > & {
            &--Open#{$root}__Item--HasChildren {
                padding-bottom: 10px;
                background: $blue-20;
            }

            &--Current,
            &--CurrentAncestor {
                &::before {
                    display: block;
                }
            }
        }
    }

    &__Link {
        padding: 6px 60px 6px 29px;
        color: $grey-90;
        line-height: 2.1rem;
        transition: background 0.2s, color 0.2s;

        @include media(md) {
            padding-top: 11px;
            padding-bottom: 11px;
            font-size: 1.8rem;
            line-height: 2.3rem;
        }

        #{$root}__Item--Parent > &,
        #{$root}__Item--Parent#{$root}__Item--Current > & {
            color: $blue;
            font-weight: $bold;
        }

        #{$root}__Item--Parent#{$root}__Item--Current > & {
            color: $text-color;
            font-weight: $bold;

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0;
                width: 4px;
                margin: auto 0;
                background-color: $blue;
                z-index: 1;
            }
        }

        #{$root}__Item--Parent > #{$root}__List--Children > #{$root}__Item > & {
            font-weight: $normal;
            line-height: 2.9rem;

            @include media(md) {
                line-height: 2.3rem;
            }

            &:hover {
                color: $blue;
            }
        }

        #{$root}__List--Children #{$root}__List--Children & {
            color: $grey-90;
        }

        #{$root}__List--Children
            #{$root}__List--Children
            #{$root}__Item--Current
            > & {
            color: $text-color;
            font-weight: $medium;
        }

        #{$root}__Item--Current > & {
            color: $blue;
            font-weight: $medium;

            &:hover {
                color: $blue;
                background: none;
            }
        }

        &:hover {
            @media (hover: none) {
                background: transparent;
            }

            #{$root}__Item--Parent &,
            #{$root}__List--Children & {
                color: $blue;
            }
        }
    }

    &__Arrow {
        width: 51px;
        max-height: 45px;

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0;
            right: 0;
            width: 2px;
            height: 16px;
            margin: auto;
            background-color: $blue;
            border-radius: 8px;
            z-index: 1;
        }

        &::after {
            transform: rotate(90deg);
        }

        #{$root}__List--Children
            #{$root}__Item--HasChildren
            > #{$root}__Link
            & {
            display: block;
        }

        #{$root}__List--Children #{$root}__Item--Open > #{$root}__Link & {
            &::before {
                transform: rotate(90deg);
            }
        }
    }
}

$basepath: '../../public/' !default;

$breakpoints: (
    xxs: 400px,
    xs: 480px,
    sm: 768px,
    md: 960px,
    lg: 1024px,
    xl: 1280px,
    xxl: 1600px,
);

$body-bg: white !default;
$text-color: black !default;
$heading-color: black !default;
$link-color: black !default;
$link-hover-color: black !default;
$error-color: red !default;
$focus-color: blue !default;

$medborgarskolan-blue-new: #211B83;

// Defined in BasePage/BaseFonts.js for each theme
$font-base: var(--font-base);
$font-heading: var(--font-heading, var(--font-base));

$light: 300 !default;
$normal: normal !default;
$medium: 500 !default;
$bold: bold !default;
$black: 900 !default;

$weight-heading: $bold !default;

$font-size-h1-d: 2.8rem !default;
$font-size-h1-md: 3.2rem !default;
$font-size-h2-d: 2.4rem !default;
$font-size-h2-sm: 2.8rem !default;
$font-size-h3-d: 2rem !default;
$font-size-h3-sm: 2.4rem !default;

$maxwidths: (
    reg: 1280px,
    site: 1160px,
    article: 1060px,
    title: 930px,
    content: 680px,
    cookie: 572px,
);

// Colors
$palettes: (
    black: (
        3: #F8F8F8,
        4: #F5F5F5,
        5: #F2F2F2,
        10: #E6E6E6,
        16: #D6D6D6,
        20: #ECECEC,
        21: #919191,
        22: #707070,
        23: #818181,
        30: #E2E2E2,
        33: #AAAAAA,
        45: #727272,
        60: #6C6C6C,
        76: #444444,
        80: #333333,
        88: #1F1F1F,
        90: #232323,
        95: #272727,
        d: #000000
    ),
    white: (
        d: #FFFFFF,
    ),
    green: (
        d: #00BFAE,
        hover: #00988B,
        5: #e9f6f5,
        system: #00a18b,
    ),
    red: (
        d: #ED1949,
        hover: #C40833,
        system: #ff005c,
    ),
    medborgarskolan: (
        blue: #217AB7,
    ),
);

$body-bg: white;
$text-color: palette(black, 88);
$heading-color: palette(black, 88);
$placeholder: palette(black, 33);
$link-color: palette(green);
$link-hover-color: palette(green, hover);
$error-color: palette(red);
$invalid-color: palette(red, system);
$valid-color: palette(green, system);
$focus-color: palette(black, 88);

$medborgarskolan-blue: palette(medborgarskolan, blue);

// Font weights
$normal: normal;
$medium: 600;
$semibold: 600;
$bold: 700;
$black: 900;

$weight-heading: $bold;

$var-text-color: $text-color;
$var-heading-color: $heading-color;
$var-focus-color: $focus-color;
$var-body-bg: $body-bg;
$var-weight-heading: $weight-heading;

$z-index: (
    d: 10,
    behind: -1,
    CookiePopup: 700,
    MenuPage: 701, // Has to be more than cookie popup
    EventItem: 10,
    EventSearchInput__Select: 11, // Has to be more than EventItem
    MenuMobile__Content: 500,
    MenuMobile__Toggle: 520,
    Header__Container: 510,
    Modal__Backdrop: 600,
    Modal: 620,
    VideoContentModule__Content: 2,
    VideoPlayer__Video: 1,
);

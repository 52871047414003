@import 'Styles/Base/includes';

/**
*
* CardLink
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardLink {
    $root: &;

    position: relative;

    &__Title {
        display: none;
        color: inherit;

        #{$root}__Link & {
            display: block;
        }
    }

    &__TitleText {
        position: relative;
    }

    &__Text {
        margin-top: 5px;

        @include media(sm) {
            font-size: 1.8rem;
        }
    }
}

$maxwidths: (
    reg: 1280px,
    site: 1160px,
    article: 1060px,
    title: 930px,
    content: 680px,
    cookie: 572px,
);

// Old colors
// $palettes: (
//     black: (
//         3: #F8F8F8,
//         4: #F5F5F5,
//         5: #F2F2F2,
//         10: #E6E6E6,
//         16: #D6D6D6,
//         20: #ECECEC,
//         21: #919191,
//         22: #707070,
//         23: #818181,
//         30: #E2E2E2,
//         33: #AAAAAA,
//         45: #727272,
//         60: #6C6C6C,
//         76: #444444,
//         80: #333333,
//         88: #1F1F1F,
//         90: #232323,
//         95: #272727,
//         d: #000000
//     ),
//     white: (
//         d: #FFFFFF,
//     ),
//     green: (
//         d: #00BFAE,
//         hover: #00988B,
//         5: #e9f6f5,
//         system: #00a18b,
//     ),
//     red: (
//         d: #ED1949,
//         hover: #C40833,
//         system: #ff005c,
//     ),
//     medborgarskolan: (
//         blue: #217AB7,
//     ),
// );

// New colors, these are not decided yet!
$grey-5: #fcfcfc;
$grey-20: #f3f2f2;
$grey-25: #e6e8e9;
$grey-30: #d9d9d9;
$grey-40: #bebebe;
$grey-50: #AAAAAA;
$grey-60: #757575;
$grey-70: #5a5a5a;
$grey-80: #393939;
$grey-90: #212121;

$red: #dc0b34;
$red-5: #fce7eb;
$red-15: #f8ccd5;
$red-60: #c80a2f;

$purple: #8c1558;

$orange: #f18a00;

$medborgarskolan-blue: #217AB7;

$system-green: #007280;
$system-red: #c62828;

$primary-color: $red;
$secondary-color: $purple;
$tertiary-color: $orange;
$gradient-color: linear-gradient(50deg, $red 17%, $purple 110%);

$body-bg: $grey-5;
$text-color: $grey-90;
$heading-color: $grey-90;
$placeholder: $grey-40;
$link-color: $primary-color;
$link-hover-color: darken($primary-color, 10%);
$focus-color: $secondary-color;

$error-color: $system-red;
$invalid-color: $system-red;
$valid-color: $system-green;

// Font weights
$light: 300;
$normal: normal;
$regular: normal;
$medium: 500;
$bold: 700;

$weight-heading: $bold;

$var-text-color: $text-color;
$var-heading-color: $heading-color;
$var-focus-color: $focus-color;
$var-body-bg: $body-bg;
$var-weight-heading: $weight-heading;

$font-size-h1-d: 2.8rem;
$font-size-h1-md: 3.2rem;
$font-size-h2-d: 2.4rem;
$font-size-h2-sm: 2.8rem;
$font-size-h3-d: 2rem;
$font-size-h3-sm: 2.4rem;

$z-index: (
    d: 10,
    behind: -1,
    CookiePopup: 700,
    MenuPage: 701, // Has to be more than cookie popup
    EventItem: 10,
    EventSearchInput__Select: 11, // Has to be more than EventItem
    MenuMobile__Content: 500,
    MenuMobile__Toggle: 520,
    Header__Container: 510,
    Modal__Backdrop: 600,
    Modal: 620,
    VideoContentModule__Content: 2,
    VideoPlayer__Video: 1,
);

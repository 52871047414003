@import 'Styles/Pahlmans/includes';

/**
*
* MenuPage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'MenuPage.Base.module.scss';

.MenuPage {
    $root: &;

    top: 0;
    left: 0;

    @include media(md) {
        font-size: 1.8rem;
    } 

    &--Active {
        position: absolute;
        width: auto;
        left: -12px;
        right: -12px;
        height: 100vh;
        z-index: z(MenuPage);

        @include media(sm) {
            left: -20px;
            right: -20px;
        }
    }

    :global(.MenuPageMobileActive) & {
        background: rgba(black, .4);
    }

    &__Content {

        #{$root}--Active & {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: 0px;
                left: 0;
                right: 0;
                height: 20px;
                pointer-events: none;
            }
        }
    }

    &__ContentInner {
        max-height: calc(100vh - 80px);
        padding-bottom: 50px;

        @include media(md) {
            padding-bottom: 0;
        }
    }

    &__List {
        &--Children {
            #{$root}__List {
                &--Children {
                    display: none;
                }
            }

            #{$root}__Item--Open > & {
                display: block;
            }
        }

        :global(.MenuPageMobileActive) & {
            background: palette(black, 15);
        }
    }

    &__Link {
        margin-bottom: 1px;
        color: $heading-color;
        font-family: $font-heading;
        font-weight: $medium;
        padding: 15px 10px 15px 30px;
        background: white;

        #{$root}--Active & {
            padding-left: 53px;
        }

        @include media(md) {
            padding-left: 30px;
        }

        &:hover {
            text-decoration: none;
            background: $menu-hover;
        }

        #{$root}__Item--Current > &,
        #{$root}__Item #{$root}__Item--CurrentAncestor &,
        #{$root}__Item #{$root}__Item--Current & {

            @include media(md) {
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0px;
                    bottom: -1px;
                    left: 0;
                    width: 4px;
                    background-color: $menu-active;
                }
            }
        }

        #{$root}__Item--Current > & {
            color: $menu-active;
        }

        #{$root}__Item--Parent > & {
            @include media(md) {
                color: $menu-active;
                text-transform: uppercase;
            }
        }

        #{$root}__List--Children #{$root}__Item--Open#{$root}__Item--HasChildren & {
            background: $menu-hover;
        }

        #{$root}__List--Children #{$root}__List--Children & {
            padding-left: 60px;
            font-weight: $normal;

            @include media(md) {
                padding-left: 45px;
            }
        }
        #{$root}__List--Children #{$root}__List--Children #{$root}__List--Children & {
            padding-left: 75px;

            @include media(md) {
                padding-left: 60px;
            }
        }
    }

    &__Arrow {
        max-height: 60px;

        &::before {
            background: $dropdown no-repeat center;
        }

        #{$root}__List--Children #{$root}__Item--HasChildren > #{$root}__Link & {
            display: block;
        }

        #{$root}__List--Children #{$root}__Item--Open > #{$root}__Link & {
            &::before {
                transform: rotate(180deg);
            }
        }
    }
}
